/* eslint-disable react-hooks/exhaustive-deps */

import { webClient } from "@api/web/WebClient";
import Logo from "@components/Logo";
import { ReshopperCard, ReshopperCardContent } from "@components/web/Card";
import { withWebLayout } from "@components/web/Decorators";
import { useLocalization } from "@hooks/localization";
import { useQueryParameter } from "@hooks/navigation";
import { Button, TextField } from "@material-ui/core";
import { useMemo, useState } from "react";
import { ApiCallError } from "../../utils/swaggerClientUtils";

export default withWebLayout(() => {
	const [token] = useQueryParameter("verification_token");

	const t = useLocalization();

	const [password, setPassword] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);


	const passwordError = useMemo(
		() => {
			if (password === undefined)
				return null;

			if (password === "")
				return t("Account_Reset_Password_NoPasswordSpecified");

			const minimumLength = 6;
			if (password.length < minimumLength) {
				return t("Account_Reset_Password_PasswordMustBeAtLeastCharacters", {
					length: minimumLength
				});
			}

			return null;
		},
		[password]);

	async function onResetPasswordClicked() {
		if (!password)
			throw new Error("Password not specified.");

		try {
			setIsLoading(true);

			await webClient().webAccountsPasswordPut({
				body: {
					resetToken: token,
					password
				}
			});

			return window.location.href = ("/account/reset-password-success");
		} catch (ex: unknown) {
			if (ex instanceof ApiCallError && ex.statusCode == 406) {
				alert(t("Account_Reset_Password_LinkExpired"));
			}
			else {
				alert("Could not reset password. Perhaps the password reset request has expired?");
			}

		} finally {

			setIsLoading(false);
		}
	}

	return <div style={{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}}>
		<Logo variant="icon-beige" style={{ marginTop: 48 }} href="/" />
		<h1>{t("Account_Reset_Password_Title")}</h1>
		<ReshopperCard style={{
			width: 300,
			alignSelf: 'center'
		}}>
			{!!token && <ReshopperCardContent>
				<TextField
					value={password}
					onChange={ev => setPassword(ev.target.value)}
					label={t("Account_Reset_Password_Hint")}
					type="password"
					error={!!passwordError}
					helperText={passwordError} />

				<Button
					disabled={!!passwordError || isLoading}
					onClick={() => onResetPasswordClicked()}
					variant="contained"
					color="primary"
					style={{
						marginTop: 32,
						alignSelf: 'flex-end'
					}}
				>
					{t("Account_Reset_Password_Update")}
				</Button>
			</ReshopperCardContent>}
		</ReshopperCard>
	</div>
});